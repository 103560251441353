const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"acumin-pro-extra-condensed",serif',

  primaryColor: '#c20f2f',
  fontColor: '#404040',
  bgColor: '#ebebeb',
  darkGreyColor:'#565855',
  redColor : '#c71016',
  darkRedColor : '#6b0a0d',
  ...overloading
}

export default variables;